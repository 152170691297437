import { useMutation } from "react-query";
import {
  apiHandlers,
  AssessmentRequestData,
  getOutlookEmailMimeContent,
} from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";

export function useReportMutation() {
  const { accessToken } = useLoginContext();
  return useMutation((data: Pick<AssessmentRequestData, "user_message">) =>
    getOutlookEmailMimeContent(accessToken).then((content) =>
      apiHandlers.assessment.check(
        {
          ...data,
          mime_content: content,
          language: getAdaptedCacheLocale(),
        },
        { headers: { Authorization: `MSN ${accessToken}` } }
      )
    )
  );
}
